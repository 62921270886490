<template>
  <div data-app>
    <div class="row mx-0 pt-3">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue"
            >New bulk message
          </span>
        </div>
        <!-- create form start -->
        <v-card class="rounded-0">
          <div class="px-4 py-7">
            <v-form ref="form" @submit.prevent="submit" class="custom-form">
              <div class="row">
                <div class="col-md-6">
                  <v-file-input
                    class="attached_by_ch"
                    style="font-size: 8px"
                    label="Attachment file"
                    v-model="file"
                  >
                  </v-file-input>
                </div>
                <div class="col-md-6" style="position: relative">
                  <b-form-select
                    v-model="role"
                    :options="roleOptions"
                    size="sm"
                    class="slect_msg_by_ch"
                    required
                  ></b-form-select>
                  <img
                    src="\images/job_application/Dropdown.svg"
                    alt=""
                    class="Dropdown_icon_by_ch"
                    width="13"
                    height="13"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <v-text-field
                    v-model="message_title"
                    label="Title"
                    required
                  ></v-text-field>
                </div>
              </div>

              <div class="row" style="margin-top: 2%">
                <div class="col-md-12">
                  <vue-editor
                    v-model="message"
                    outlined
                    name="input-7-4"
                    label="Message"
                    rows="6"
                  ></vue-editor>
                </div>
              </div>

              <v-card-actions>
                <!-- <v-btn
                  :disabled="submitting"
                  class="custom-submit-button"
                  type="submit"
                  ><b-spinner small type="grow" v-if="submitting"></b-spinner>
                  <span>Submit</span>
                </v-btn> -->
                <button
                  type="button"
                  :disabled="submitting"
                  @click="createJobShift"
                  class="custom-add-new-record-button btn_dash_ch_new"
                >
                  <b-spinner small type="grow" v-if="submitting"></b-spinner>
                  <span
                    class="v-btn__content px-4"
                    style="font-size: 14px !important"
                    >Send</span
                  >
                </button>
                <button
                  type="button"
                  @click="cancel"
                  class="custom-add-new-record-button btn_dash_ch_new2 ml-2"
                >
                  <span
                    class="v-btn__content px-4"
                    style="font-size: 14px !important"
                    >Cancel</span
                  >
                </button>
              </v-card-actions>
            </v-form>
          </div>
        </v-card>
        <!-- create form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      token: `Bearer ${localStorage.getItem("token")}`,
      file: null,
      message_title: "",
      message: "",
      role: null,
      roleOptions: [
        { value: null, text: "Choose receipt" },
        { value: [1], text: "Job Seeker" },
        { value: [2], text: "Direct Employer" },
        { value: [1, 2], text: "All Job Seekers & All Recruiters" },
      ],
    };
  },

  components: {
    VueEditor,
  },

  methods: {
    submit() {
      this.submitting = true;
      let formData = new FormData();
      formData.append("file", this.image);
      formData.append("message_title", this.message_title);
      formData.append("message", this.message);
      formData.append("role", JSON.stringify(this.role));

      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/bulk-message`, formData)
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.submitting = false;
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.resetForm();
        })
        .catch(() => {
          this.loading = false;
          this.submitting = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    cancel() {
      this.resetForm();
    },
    resetForm() {
      this.message_title = "";
      this.message = "";
      this.file = null;
      this.role = null;
    },
  },
};
</script>
